import { LinkIcon } from "@akord/addon-icons";

export const shareDialoglViewProps = {
  dialogName: "shareFile",
  Icon: LinkIcon,
  title: "Share public file",
  placeholder: null,
  secondaryText: "Anyone you share this link with will able to view the image.",
  nextButton: "Copy link"
};

export const shareDialogViewOnSubmit = (inputValue: string) =>
  navigator.clipboard.writeText(inputValue)
