import { ImageIcon } from '@akord/addon-icons';
import { Box } from '@mui/material';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useInViewport } from 'react-in-viewport';
import FileViewerError from '../FileViewerError';
import Image from '../Image';
import Note from '../Note';
import Pdf from '../Pdf';
import Csv from '../Csv';
import Video from '../Video';
import Doc from '../Doc';
import { FileProps, FileType } from './types';

const File = forwardRef((props: FileProps, ref: Ref<{ fileName: string, hash: string, type: FileType, isLoading: boolean, print: () => void, download: () => void }>) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)
  const [loadedSrc, setLoadedSrc] = useState<string>()
  const [loadedFileName, setLoadedFileName] = useState<string>()
  const [loadedType, setLoadedType] = useState<FileType>()
  const loaderRef = useRef();
  const {
    inViewport
  } = useInViewport(
    loaderRef,
    {},
    { disconnectOnLeave: false },
    props
  );

  useEffect(() => {
    if (!loadedFileName || !loadedSrc || !loadedType) {
      init()
    }
  }, [inViewport])

  useEffect(() => {
    if (props.onLoad && loadedFileName && loadedType && loadedSrc) {
      props.onLoadSuccess(props.hash, loadedFileName, loadedType, loadedSrc)
    }
  }, [loadedFileName, loadedType, loadedSrc])

  useImperativeHandle(ref, () => ({
    fileName: loadedFileName,
    type: loadedType,
    hash: props.hash,
    isLoading: isLoading,
    print, download,
  }))

  const init = async () => {
    if (props.onLoad && props.toLoad) {
      if (inViewport) {
        const { fileName, src, type } = await props.onLoad(props.toLoad)
        setLoadedFileName(fileName)
        setLoadedSrc(src)
        setLoadedType(type)
      }
    }
    else if (props.src instanceof Promise) {
      props.src
        .then(src => {
          setLoadedFileName(props.fileName)
          setLoadedType(props.type)
          setLoadedSrc(src)
        })
        .catch(e => {
          console.error(e)
          setIsError(true)
        })
        .finally(() => setIsLoading(false))
    }
    else {
      if (props.fetch) {
        const res = await fetch(props.src)
        const buffer = await res.arrayBuffer()
        setLoadedSrc(URL.createObjectURL(new Blob([buffer])));
      }
      else {
        setLoadedFileName(props.fileName)
        setLoadedType(props.type)
        setLoadedSrc(props.src)
      }
    }
    setIsLoading(false)
  }

  const getDriver = () => {
    switch (loadedType?.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'bmp':
      case 'png':
        return Image
      case 'mp4':
      case 'mkv':
      case 'mp3':
        return Video
      case 'pdf':
        return Pdf
      case 'csv':
        return Csv
      case 'doc':
      case 'docx':
        return Doc
      case 'note':
        return Note
      default:
        if (!isError) {
          setIsError(true)
        }
        return null
    }
  }

  const closePrint = (printPopup: Window | null) => {
    if (printPopup) {
      printPopup.close();
    }
  }
  const print = async () => {
    const printPopup = window.open(loadedSrc, loadedFileName);
    if (printPopup) {
      printPopup.onbeforeunload = () => closePrint(printPopup);
      printPopup.onafterprint = () => closePrint;
      printPopup.focus(); // Required for IE
      printPopup.print();
    }
  }

  const download = async () => {
    const link = document.createElement("a");
    link.href = loadedSrc;
    link.download = loadedFileName;
    link.click();
  }

  const renderLoader = () => <Box
    ref={loaderRef}
    height={props.height}
    width={props.width}
    sx={{
      bgcolor: 'background.card',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <ImageIcon sx={{ fontSize: '100px', margin: `${props.height / 3}px` }} color='secondary'></ImageIcon>
  </Box>

  if (!loadedSrc || !loadedType) {
    return renderLoader()
  }

  const renderFile = () => {
    if (isLoading) {
     return renderLoader()
    }
    else if(isError) {
      return <FileViewerError src={loadedSrc} name={loadedFileName} mode={props.mode} width={props.width} height={props.height}></FileViewerError>
    }
    else {
      return Viewer && <Viewer
          width={props.width}
          height={props.height}
          src={loadedSrc}
          className={props.className}
          fixedHeight={props.mode === 'group'}
        />
    }
  }
  const Viewer = getDriver()

  return (
    <Box>
      {renderFile()}
    </Box>
  )
})

export default File
