import { ChevronLeftIcon, ChevronRightIcon } from '@akord/addon-icons';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { grey } from '../../../theme/colors';
import { Props } from '../types';
import "./styles.css";


const Pdf = (props: Props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [navButtonsHideTimeout, setNavButtonsHideTimeout] = useState(null);
  const navButtons = useRef<HTMLDivElement>()

  const defaultWorker = () => {
    if (!pdfjs.GlobalWorkerOptions.workerPort) {
      pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
      pdfjs.GlobalWorkerOptions.workerPort = new pdfjs.PDFWorker('default-worker');
    }
    return pdfjs.GlobalWorkerOptions.workerPort
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
    handleMouseMove();
  }

  const changePage = (offset: number) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
    handleMouseMove();
  }

  const previousPage = () => {
    changePage(-1);
  }

  const nextPage = () => {
    changePage(1);
  }

  const handleMouseEnter = () => {
    if (navButtons.current) {
      navButtons.current.style.opacity = '90%';
      if (navButtonsHideTimeout) {
        clearTimeout(navButtonsHideTimeout);
      }
    }
  }

  const handleMouseLeave = () => {
    setNavButtonsHideTimeout(setTimeout(() => {
      if (navButtons.current) {
        navButtons.current.style.opacity = '0'
      }
    }, 2000))
  }

  const handleMouseMove = () => {
    handleMouseEnter();
    handleMouseLeave();
  }

  return (
    <Box
      style={{
        minWidth: `${props.width}px`,
        height: `${props.height}px`,
      }}
      onMouseMove={() => handleMouseMove()}>
      <Document
        file={props.src}
        options={{ worker: defaultWorker() }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={pageNumber}
          //    scale={5}
          className={[props.className, 'pdf-canvas-container']}
          height={props.height}
          style={{
            display: 'inline-block',
            transform: 'none'
          }}
        />
      </Document>

      <Box
        sx={{
          position: 'sticky',
          display: 'inline-block',
          bottom: '5px',
          left: '50%',
          backgroundColor: grey[400],
          opacity: '90%',
          borderRadius: '5px',
          transform: 'translate(-50%, 0)',
          transition: 'opacity ease-out 0.5s'
        }}
        ref={e => (navButtons.current = (e as HTMLDivElement))}
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}>
        <IconButton
          disabled={pageNumber <= 1}
          onClick={previousPage}
          sx={{
            padding: '7px',
            marginLeft: '5px',
            color: grey[300],
            ':hover': {
              color: 'white',
            },
          }}
        >
          <ChevronLeftIcon sx={{ fontSize: '24px', transform: 'none !important' }} />
        </IconButton>
        <Typography variant="caption" color='white'>
          {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </Typography>
        <IconButton
          disabled={pageNumber >= numPages}
          onClick={nextPage}
          sx={{
            padding: '7px',
            marginRight: '5px',
            color: grey[300],
            ':hover': {
              color: 'white',
            }
          }}
        >
          <ChevronRightIcon sx={{ fontSize: '24px', transform: 'none !important' }} />
        </IconButton>
      </Box>
    </Box>
  );
}

export default Pdf
