import React from "react";
import { styled } from "@mui/material/styles";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { AddMemberIcon } from "@akord/addon-icons";

const AkordAlert = styled(MuiAlert)`
    padding: 12,
    paddingRight: 16,
  & .MuiAlert-filled: {
    backgroundcolor: "#288032";
  },
  & .MuiAlert-icon: {
    fontSize: 24,
    padding: 0,
    marginRight: 8
  },
  & .MuiAlert-message: {
    fontFamily: [
      "Larsseit-Regular",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen-Sans",
      "Ubuntu",
      "Cantarell",
      "Helvetica Neue",
      "sans-serif"
    ].join(","),
    fontSize: 16,
    padding: 0,
    marginTop: 2,
    whiteSpace: "pre-line"
  }
`;

export interface SnackbarNotification {
  snackbarViewProps?: SnackbarViewProps;
  snackbarShow?: boolean;
  onSnackbarClose?: () => void;
  snackbarData?: SnackbarData;
}

export interface SnackbarViewProps {
  Icon: (props: any) => React.ReactElement<any, any>;
  altText?: string;
  text: string;
}

export type AlertColor = "success" | "info" | "warning" | "error";

export interface SnackbarData {
  snackbarType?: string;
  dynamicData?: string;
  severity?: AlertColor;
}

const SnackbarNotification = (props: SnackbarNotification) => {
  const { snackbarViewProps, onSnackbarClose, snackbarData, snackbarShow } =
    props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    onSnackbarClose();
  };

  const SnackBarIcon = snackbarViewProps?.Icon
    ? snackbarViewProps.Icon
    : AddMemberIcon;

  const snackbarText = snackbarData?.dynamicData
    ? snackbarData.dynamicData + " " + snackbarViewProps.altText
    : snackbarViewProps?.text;

  return (
    <Snackbar
      open={snackbarShow}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
    >
      <AkordAlert
        elevation={4}
        severity={snackbarData?.severity || "success"}
        variant="filled"
        iconMapping={{
          success: <SnackBarIcon fontSize="inherit" />
        }}
      >
        {snackbarText}
      </AkordAlert>
    </Snackbar>
  );
}

export default SnackbarNotification;
