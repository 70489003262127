import { AudioIcon, ChevronDownIcon, ChevronUpIcon, DocumentIcon, ImageIcon, NoteIcon, VideoIcon } from '@akord/addon-icons';
import {
    Box,
    Chip,
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useMediaQuery
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { FileInput } from '../../Gallery/types';
import { FileType } from '../File/types';

export type Props = {
    files: Array<FileInput>
    onSelect: (hash: string) => void
}

const FileVersionSelect = (props: Props) => {

    const [opened, setOpened] = useState(false)
    const [files, setFiles] = useState([])
    const wrapperRef = useRef(null);
    const isMobile = useMediaQuery("(max-width:668px)");
    const isTablet = useMediaQuery("(max-width:1100px)");

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setOpened(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    useEffect(() => {
        const inFiles = [...props.files]
        setFiles(inFiles)
    }, [props.files]);

    const handleFileListClick = () => {
        if (props.files && props.files.length > 1) {
            setOpened(!opened)
        }
    }

    const getFileIcon = () => {
        if (files) {
            switch (files[0].type) {
                case FileType.bmp:
                case FileType.jpg:
                case FileType.jpeg:
                case FileType.png:
                    return ImageIcon
                case FileType.mp3:
                    return AudioIcon
                case FileType.mp4:
                    return VideoIcon
                case FileType.note:
                    return NoteIcon
                default:
                    return DocumentIcon
            }
        }
        return null
    };

    if (!files || !files.length) {
        return null
    }

    const FileIcon = getFileIcon()
    const latestFile = files.find(file => file.version == files.length - 1)

    return (
        <Box ref={wrapperRef} sx={{
            position: 'absolute',
            top: isMobile ? 0 : '16px',
            left: isMobile ? 'none' : '50%',
            transform: isMobile ? 'none' : 'translate(-50%, 0)',
            borderRadius: 1,
            backgroundColor: 'background.card',
            marginLeft: 0,
            width: isTablet ? 'calc(100% - 32px)' : '400px',
            zIndex: 1000
        }}>
            <List component="nav" disablePadding aria-labelledby="file-list">
                <ListItem
                    disableGutters
                    divider={opened}
                    button
                    onClick={handleFileListClick}
                    sx={{
                        alignItems: 'center',
                        padding: 3,
                        paddingRight: 2,
                        height: '44px',
                        '&:first-of-type': {
                            paddingTop: 3
                        }
                    }}
                >
                    <ListItemIcon>
                        <FileIcon sx={{ color: 'background.icon' }} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{
                            variant: 'body2',
                            className: 'small',
                            color: 'textPrimary',
                            noWrap: true
                        }}
                        primary={
                            latestFile ? latestFile.name : files[0].name
                        }
                    />
                    {(files && files.length > 1) &&
                        <Chip
                            size="small"
                            variant={
                                files &&
                                    files.length == 1
                                    ? 'outlined'
                                    : 'filled'
                            }
                            label={files[0].version == files.length - 1 ? 'Latest' : "V" + (files[0].version + 1)}
                            color="primary"
                            style={{ marginRight: '6px', marginLeft: '5px' }}
                        />
                    }
                    {props.files && props.files.length > 1 ? (
                        opened ? (
                            <ChevronUpIcon />
                        ) : (
                            <ChevronDownIcon />
                        )
                    ) : null}
                </ListItem>
                <Collapse in={opened} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {files && files.length > 1 &&
                            files.slice(1).map((file, index) =>
                            (
                                <Box key={index}>
                                    <ListItem
                                        divider={files.length - 2 !== index}
                                        disableGutters
                                        button
                                        onClick={() => {
                                            setOpened(false)
                                            props.onSelect(file.hash)
                                        }}
                                        sx={{
                                            alignItems: 'center',
                                            padding: 3,
                                            paddingRight: 2,
                                            height: '44px',
                                            '&:first-of-type': {
                                                paddingTop: 3
                                            }
                                        }}
                                    >
                                        <ListItemText
                                            primaryTypographyProps={{
                                                variant: 'body2',
                                                className: 'small',
                                                color: 'textPrimary',
                                                noWrap: true
                                            }}
                                            primary={
                                                latestFile ? latestFile.name : files[0].name
                                            }
                                        />
                                        <Chip
                                            size="small"
                                            color="primary"
                                            variant='filled'
                                            label={
                                                file.version === files.length -1 ?
                                                    'Latest' : 'V' + (file.version + 1)
                                            }
                                            style={{ marginRight: '30px', marginLeft: '5px' }}
                                        />
                                    </ListItem>
                                </Box>
                            )
                            )}
                    </List>
                </Collapse>
            </List>
        </Box>
    )
}

export default FileVersionSelect
