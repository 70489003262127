import React from 'react'
import { CardMedia } from '@mui/material'
import { Props } from '../types'

const Video = (props: Props) => {
  return (
    <CardMedia
      component='video'
      image={props.src}
      height={props.height}
      controls
      sx={
        {
          width: `${props.width}px`,
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }
      }
  />
  )
}

export default Video
