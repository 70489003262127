import { Box, useMediaQuery, useTheme } from '@mui/material'
import Editor from "@akord/rich-markdown-editor";
import React, { useEffect, useState } from 'react'
import { Props } from '../types'
import light, { dark } from './theme';

const Note = (props: Props) => {
  const theme = useTheme()
  const isDarkMode = theme.palette.mode === 'dark'
  const isMobile = useMediaQuery("(max-width:668px)");
  const [content, setContent] = useState('')
  
  useEffect(() => {
    setContent(props.src)
  }, [])
  
  return (
    <Box
      sx={{
        height: `${props.height - 72}px`,
        overflow: 'auto',
        marginLeft: isMobile ? 4 : "auto",
        marginRight: isMobile ? 4 : "auto",
        marginTop: isMobile ? 5 : 10,
        width: `${props.width}px`,
        textAlign: 'left'
      }}>
    <Editor
      style={{ fontSize: "18px" }}
      hideDropDownToolbar
      autoFocus
      readOnly
      theme={isDarkMode ? dark : light}
      disableExtensions={[
        "code_inline",
        "highlight",
        "em",
        "link",
        "placeholder",
        "strikethrough",
        "underline",
        "blockquote",
        "checkbox_item",
        "checkbox_list",
        "code_block",
        "code_fence",
        "embed",
        "hr",
        "image",
        "container_notice",
        "table",
        "td",
        "th",
        "tr",
        "emoji"
      ]}
      defaultValue={content}
      value={content}
      dictionary={{ newLineEmpty: "" }}
    />
  </Box>
  )
}

export default Note
