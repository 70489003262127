import { ArrowLeftIcon, ArrowRightIcon, ArweavePermanentIcon, ChevronLeftIcon, ChevronRightIcon, CloseIcon, DarkModeGalleryIcon, DoubleChevronLeftIcon, DownloadIcon, GroupTileIcon, ImageIcon, LightModeGalleryIcon, LinkIcon, MenuDotsHorizontalIcon, PrinterIcon, VaultIcon, ViewTransactionIcon, 
} from '@akord/addon-icons';
import { Box, Button, Divider, Drawer, Fab, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { HashNavigation, Keyboard, Navigation, Pagination, Zoom } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import SwiperClass from 'swiper/types/swiper-class';
import { grey } from '../../theme/colors';
import File from '../FileViewer/File/File';
import { FileType } from '../FileViewer/File/types';
import FileVersionSelect from '../FileViewer/FileVersionSelect/FileVersionSelect';
import LabelSpinner from '../Loader/LabelSpinner';
import DialogWithForm from "../Dialogs/DialogWithForm";
import './styles.css';
import { FileInput, GalleryProps, Handle } from './types';
import {
  shareDialoglViewProps,
  shareDialogViewOnSubmit
} from "../Dialogs/DialogWithForm/share-form-modal-options";
import { shareSnackbarViewProps } from '../SnackbarNotification/share-snackbar-options';
import SnackbarNotification from '../SnackbarNotification';

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(function () {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

SwiperCore.use([HashNavigation, Keyboard, Navigation, Pagination, Zoom]);

const Gallery = (props: GalleryProps) => {
    const isMobile = useMediaQuery("(max-width:668px)");
    const isTablet = useMediaQuery("(max-width:1100px)");

    const [files, setFiles] = useState<Array<FileInput>>([]);
    const [groupedFiles, setGroupedFiles] = useState<Map<String, Array<FileInput>>>();
    const [activeFileGroup, setActiveFileGroup] = useState<Array<FileInput>>();
    const [activeFile, setActiveFile] = useState<Handle<typeof File>>();
    const [activeFileIndex, setActiveFileIndex] = useState<number>(0);
    const [swiperRerenders, setSwiperRerenders] = useState<number>(0);
    const [swiper, setSwiper] = useState<SwiperClass>();
    const [darkMode, setDarkMode] = useState<Boolean>(props.darkMode);
    const [mode, setMode] = useState<'single' | 'group'>(isTablet ? 'single' : props.mode || 'group');
    const [showPagination, setShowPagination] = useState(true);
    const [isZoomed, setIsZoomed] = useState(false);
    const [isDrawerOpened, setIsDrawerOpened] = useState(false);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const filesRef = useRef<Array<Handle<typeof File>>>([]);

    const BOTTOM_NAVIGATION_VERTICAL_SIZE = 150
    const BOTTOM_NAVIGATION_VERTICAL_SIZE_MOBILE = 240

    useEffect(() => {
        loadFiles()
        initDimenistions()
    }, [])

    useEffect(() => {
        if (swiper) {
            swiper.update()
        }
        window.addEventListener('resize', () => {
            handleResize(updateSwiper)
        });
        return () => window.removeEventListener("resize", updateSwiper);
    }, [swiper])

    const handleResize = debounce(
        () => {
            if (!document.fullscreenElement) {
                initDimenistions()
                updateSwiper()
            }
        },
        200
    );

    useEffect(() => {
        if (!activeFile && filesRef && filesRef.current) {
            setActiveFile(filesRef.current[0])
        }
    }, [activeFile, filesRef.current])

    useEffect(() => {
        if (mode === 'single') {
            setShowPagination(false)
        }
        else if (mode === 'group') {
            setShowPagination(true)
        }
        updateSwiper()
    }, [mode])

    useEffect(() => {
        filesRef.current = filesRef.current.slice(0, files.length);
    }, [files]);

    useEffect(() => {
        if (groupedFiles) {
            setActiveFileGroup(Array.from(groupedFiles.values())[activeFileIndex])
        }
    }, [activeFileIndex, groupedFiles]);

    useEffect(() => {
        if (isTablet) {
            setMode('single')
            initDimenistions()
        }
    }, [isTablet]);

    const loadFiles = () => {
        const groupedFiles = new Map()
        props.files.forEach((file) => {
            const key = file.group || file.hash
            if (!groupedFiles.has(key)) {
                groupedFiles.set(key, [file])
            } else {
                groupedFiles.get(key).push(file)
            }
        })
        Array.from(groupedFiles.keys())
            .forEach(key => groupedFiles.get(key).sort((a, b) => b.version - a.version))

        const firstInGroupFiles = Array.from(groupedFiles.values()).map(group => group[0])
        if (window.location.hash) {
            const urlActiveFileIndex = firstInGroupFiles.findIndex(file => window.location.hash.includes(file.hash))
            if (urlActiveFileIndex > 0) {
                setActiveFileIndex(urlActiveFileIndex)
            }
        }
        setFiles(firstInGroupFiles)
        setGroupedFiles(groupedFiles)
    }

    const initDimenistions = () => {
        const { height, width } = getDimensions()
        setWidth(width)
        setHeight(height)
    }

    const updateSwiper = () => {
        setSwiperRerenders(swiperRerenders + 1)
        updateSwiperSlides()
    }

    const updateSwiperSlides = () => {
        if (swiper) {
            setTimeout(() => swiper.update(), 300)
        }
    }

    const getHeight = (): number => {
        if (props.height === 'auto') {
            if (isTablet) {
                return window.innerHeight - BOTTOM_NAVIGATION_VERTICAL_SIZE_MOBILE
            }
            return window.innerHeight - BOTTOM_NAVIGATION_VERTICAL_SIZE
        }
        return props.height
    }

    const getDimensions = () => {
        const height = getHeight()
        const width = Math.min(height * 3 / 4, window.innerWidth)
        return { height, width }
    }

    const hasRevisions = () => {
        return props.groupFilesEnabled
    }

    const slideChangedHandler = (activeSlideIndex: number) => {
        setActiveFileIndex(activeSlideIndex)
        setActiveFile(filesRef.current[activeSlideIndex])
        setActiveFileGroup(Array.from(groupedFiles.values())[activeSlideIndex])
        setRevisionByIndex(0)
    }

    const setRevisionByHash = (hash: string) => {
        if (hasRevisions()) {
            const index = activeFileGroup.findIndex(file => file.hash === hash)
            if (index) {
                const fileGroup = [activeFileGroup[index], ...activeFileGroup.slice(0, index), ...activeFileGroup.slice(index + 1)]
                setActiveFileGroup(fileGroup)
                const groups = new Map(groupedFiles)
                groups.set(activeFileGroup[index].group, fileGroup)
                setGroupedFiles(groups)
                setFiles([
                    ...files.slice(0, activeFileIndex),
                    activeFileGroup[index],
                    ...files.slice(activeFileIndex + 1)
                ])
                updateSwiper()
            }
        }
    }

    const setRevisionByIndex = (index: number) => {
        if (hasRevisions()) {
            const revision = activeFileGroup[index]
            setFiles([
                ...files.slice(0, activeFileIndex),
                revision,
                ...files.slice(activeFileIndex + 1)
            ])
        }
    }

    const onClose = () => {
        if (swiper) {
            swiper.destroy()
        }
        if (props.onClose) {
            props.onClose()
        }
        else if (props.closeUrl) {
            window.location.href = props.closeUrl
        } else {
            history.back()
        }
    }

    const print = () => {
        if (activeFile) {
            filesRef.current[activeFileIndex].print()
        }
    }

    const download = () => {
        if (activeFile) {
            filesRef.current[activeFileIndex].download()
        }
    }

    const onFileLoadSuccess = (hash: string, fileName: string, type: FileType, src: string) => {

        const index = files.findIndex(file => file.hash === hash)
        const file = files[index]
        file.name = fileName
        file.type = type
        file.src = src
        file.onLoad = null
        file.toLoad = null
        setFiles([
            ...files.slice(0, index),
            file,
            ...files.slice(index + 1)
        ])


        if (activeFile?.hash === hash) {
            const currentFile = { ...activeFile }
            currentFile.fileName = fileName
            currentFile.type = type
            currentFile.isLoading = false
            setActiveFile(currentFile)

            const activeGroupIndex = activeFileGroup.findIndex(file => file.hash === hash)

            activeFileGroup[activeGroupIndex].name = fileName
            activeFileGroup[activeGroupIndex].type = type
            activeFileGroup[activeGroupIndex].src = src
            setActiveFileGroup([...activeFileGroup])

            groupedFiles.set(file.group, activeFileGroup)
            setGroupedFiles(groupedFiles)
        }
        updateSwiperSlides()
    }

    const renderNavigationArrows = () => {
        if (showPagination) {
            return [
                <Box key='prev' className='swiper-button-prev'>
                    <ChevronLeftIcon
                        sx={{
                            paddingRight: 5,
                            paddingBottom: 1,
                            ':hover': {
                                color: 'text.primary',
                            }
                        }}
                        color='secondary' />
                </Box>,
                <Box key='next' className='swiper-button-next'>
                    <ChevronRightIcon
                        sx={{
                            paddingLeft: 5,
                            paddingBottom: 1,
                            ':hover': {
                                color: 'text.primary',
                            }
                        }}
                        color='secondary' />
                </Box>
            ]
        } else {
            return [
                <Box key="arweaveLogo" position="absolute" display={props.arweaveLogoEnabled ? "flex" : "none"} sx={{bottom: 0, left: 16}}>
                  <ArweavePermanentIcon 
                    color='primary' 
                    sx={{width: '3.7em', height: 'auto', fontSize: '28px'}}/>
                </Box>,
                <Box key='prev' className='swiper-button-prev'
                    style={{
                        borderRadius: '50%',
                        marginRight: '16px',
                        marginTop: '16px'
                    }}>
                    <IconButton sx={{
                        borderRadius: '50%',
                        width: 40,
                        height: 40,
                        bgcolor: 'background.tertiary',
                        ':hover': {
                            bgcolor: 'background.tertiary',
                        },
                    }}>
                        <ArrowLeftIcon
                            sx={{
                                color: grey[400],
                                ':hover': {
                                    color: 'text.primary',
                                },
                            }}></ArrowLeftIcon>
                    </IconButton>
                </Box>,
                <Box key='next' className='swiper-button-next'
                    style={{
                        marginLeft: '16px',
                        marginTop: '16px'
                    }}>
                    <IconButton sx={{
                        borderRadius: '50%',
                        width: 40,
                        height: 40,
                        bgcolor: 'background.tertiary',
                        ':hover': {
                            bgcolor: 'background.tertiary',
                        },
                    }}>
                        <ArrowRightIcon
                            sx={{
                                color: grey[400],
                                ':hover': {
                                    color: 'text.primary',
                                },
                            }}></ArrowRightIcon>
                    </IconButton>
                </Box>
            ]
        }
    }

    const renderFilesSlides = () => {
        if (!files || !files.length) {
            return null
        }
        return files.map((file, idx) => {
            return (
                <SwiperSlide key={idx} data-hash={file.hash}>
                    <File
                        fileName={file.name}
                        hash={file.hash}
                        src={file.src}
                        type={file.type}
                        toLoad={file.toLoad}
                        onLoad={file.onLoad}
                        mode={mode}
                        width={width}
                        height={height}
                        className='swiper-zoom-container'
                        ref={el => filesRef.current[idx] = el}
                        onLoadSuccess={onFileLoadSuccess}
                    />
                </SwiperSlide>
            )
        })
    }

    const renderLocation = () => {
        if (props.navigationComponent) {
            return props.navigationComponent
        }
        if (props.navigation) {
            const folders = props.navigation.split(',')
            if (folders.length > 1) {
                const root = folders.shift()
                return <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <IconButton>
                        <DoubleChevronLeftIcon sx={{ fontSize: '24px' }}></DoubleChevronLeftIcon>
                    </IconButton>
                    <Typography sx={{ marginLeft: 2, marginTop: 1, color: 'text.secondary' }}>{root} / </Typography>
                    {folders.map((folder, idx) => (
                        <Typography key={idx} variant="h3" sx={{ marginLeft: 2, marginTop: 1 }}>{folder} {idx !== folders.length - 1 ? '/' : ''} </Typography>
                    ))}
                </Box>
            }
            return <Box fontWeight='bold' display='flex' justifyContent='space-between' alignItems='center'>
                <VaultIcon color='secondary' sx={{ fontSize: '24px' }}></VaultIcon>
                <Typography variant="h3" sx={{ marginLeft: 2, marginTop: 1 }}>{props.navigation}</Typography>
            </Box>
        }
        return null
    }

    const handleModalOpen = () => {
      setModalOpen(!modalOpen);
    }

    const handleSnackbarOpen = () => {
      setSnackbarOpen(!snackbarOpen);
    };

    if (!groupedFiles || !activeFileGroup) {
        return null
    }

    return (
        <Box className='host'>
            <Box sx={{
                minHeight: '31px',
                margin: 4,
                marginBottom: isMobile ? 2 : isTablet ? 0 : 6
            }}
                display='flex' justifyContent='space-between' alignItems='center'>
                {renderLocation()}
                {props.groupFilesEnabled && mode === 'single' && !isTablet &&
                    <Box width='25%'>
                        <FileVersionSelect files={activeFileGroup} onSelect={setRevisionByHash}></FileVersionSelect>
                    </Box>
                }
                {!isMobile &&
                    <Box display='flex'>
                        {(!activeFileGroup || !activeFileGroup[0].name) && !isMobile && props.loaderEnabled && <LabelSpinner label={props.loaderText} sx={{ marginRight: 4 }}></LabelSpinner>}
                        {props.publicShareEnabled && <Button
                          variant="contained"
                          size="xs"
                          color="primary"
                          sx={{ marginRight: 4 }}
                          onClick={() => handleModalOpen()}
                        >
                          Share
                        </Button>}
                        {props.modeSwitchEnabled && !isMobile &&
                            mode === 'group' &&
                            <Tooltip title="Single" arrow>
                                <IconButton size="large" sx={{ marginRight: 3 }} onClick={() => setMode('single')}>
                                    <ImageIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        }
                        {props.modeSwitchEnabled && !isMobile &&
                            mode === 'single' &&
                            <Tooltip title="Gallery" arrow>
                                <IconButton size="large" sx={{ marginRight: 3 }} onClick={() => setMode('group')}>
                                    <GroupTileIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        }
                        {props.themeSwitchEnabled &&
                            <Tooltip title={darkMode ? "Light mode" : "Dark mode"} arrow>
                                <IconButton size="large" sx={{ marginRight: 3 }} onClick={() => {
                                    setDarkMode(!darkMode)
                                    props.onThemeSwitch && props.onThemeSwitch()
                                }}>
                                    {darkMode ? <LightModeGalleryIcon /> : <DarkModeGalleryIcon />}
                                </IconButton>
                            </Tooltip>
                        }
                        {props.viewTransactionEnabled &&
                            <Tooltip title="View transaction" arrow>
                                <IconButton size="large" sx={{ marginRight: 3 }} onClick={()=> window.open(`${props.transactionBaseUrl}/${activeFileGroup[0].txId}`, "_blank")}>
                                    <ViewTransactionIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {props.printEnabled &&
                            <Tooltip title="Print" arrow>
                                <IconButton size="large" sx={{ marginRight: 3 }} onClick={() => print()}
                                    disabled={
                                        activeFileGroup[0].type === FileType.note
                                        || activeFileGroup[0].type === FileType.mp3
                                        || activeFileGroup[0].type === FileType.mp4
                                    }>
                                    <PrinterIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {props.downloadEnabled &&
                            <Tooltip title="Download" arrow>
                                <IconButton size="large" sx={{ marginRight: 3 }} onClick={() => download()}
                                    disabled={activeFileGroup[0].type === FileType.note}>
                                    <DownloadIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip title="Close" arrow>
                            <IconButton size="large" onClick={() => onClose()}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
            </Box>
            {isTablet &&
                <Box sx={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 2,
                    paddingRight: 2,
                    minHeight: '56px',
                    justifyContent: 'space-evenly',
                    marginBottom: isMobile ? 3 : 6
                }}>
                    <FileVersionSelect files={activeFileGroup} onSelect={setRevisionByHash}></FileVersionSelect>
                </Box>
            }
            <Box className={isZoomed ? 'zoomed' : ''}>
                <Swiper
                    key={swiperRerenders}
                    onSwiper={(swiperInstance) => {
                        setSwiper(swiperInstance)
                    }}
                    onSlideChange={(swiperInstance) => slideChangedHandler(swiperInstance.activeIndex)}
                    onTap={(_s, e) => {
                        const targetElementType = (e.target as HTMLElement).localName
                        if (swiper && targetElementType !== 'button' && targetElementType !== 'anchor' && targetElementType !== 'svg') {
                            swiper.emit('doubleTap', e)
                        }
                    }
                    }
                    onZoomChange={() => setIsZoomed(!isZoomed)}
                    //  modules={[Pagination, Navigation, HashNavigation, Keyboard, Zoom]}
                    initialSlide={activeFileIndex}
                    keyboard={{
                        enabled: true,
                        onlyInViewport: false,
                    }}
                    hashNavigation={{
                        replaceState: true,
                        watchState: true
                    }}
                    zoom={true}
                    loop={false}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={mode === 'single' ? 1 : 'auto'}
                    spaceBetween={80}
                    speed={1000}
                    watchOverflow={false}
                    pagination={{
                        el: ".swiper-pagination",
                        type: "fraction",
                        clickable: true
                    }}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                >
                    {files && files.length && renderFilesSlides()}
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: 2
                    }}>
                        {mode === 'group' && <Typography variant="body2" color="textPrimary" sx={{
                            marginTop: 5,
                            marginBottom: 2
                        }}>{activeFile?.fileName}</Typography>}
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'center', position: 'initial' }}>
                        {showPagination && <Box sx={{ color: 'background.switch', typography: 'caption' }} className="swiper-pagination" />}
                        {renderNavigationArrows()}
                    </Box>
                </Swiper> 
            </Box>
            {isMobile && (
                <>
                    <Fab
                        size="medium"
                        color="primary"
                        sx={{
                            position: "fixed",
                            right: "16px",
                            bottom: "16px"
                        }}
                        onClick={() => {
                            setIsDrawerOpened(true);
                        }}
                    >
                        <MenuDotsHorizontalIcon sx={{ fontSize: "30px" }} />
                    </Fab>
                    <Drawer
                        anchor='bottom'
                        elevation={16}
                        open={isDrawerOpened}
                        sx={{ zIndex: 1300 }}
                        onClose={() => setIsDrawerOpened(!isDrawerOpened)}
                    >
                        <Box mx={5}>
                            <List
                                component="nav"
                                aria-label="active files"
                                style={{ listStyleType: "none" }}>
                                <Box my={3}>
                                    <ListItem disableGutters sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        paddingBottom: "0px"
                                    }}>
                                        <ListItemIcon>
                                            <ImageIcon
                                                color="action"
                                                sx={{
                                                    boxSizing: "content-box",
                                                    padding: "5px",
                                                    background: 'background.stack',
                                                    color: 'info.secondary',
                                                    fontSize: '20px'
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography noWrap variant="body2" color="text.primary">
                                                {activeFile?.fileName}
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                </Box>
                                <Divider />
                                <Box my={6}>
                                    <ListItemButton
                                        sx={{
                                            paddingLeft: "0px",
                                            paddingTop: "0px",
                                            paddingBottom: "0px"
                                        }}
                                        onClick={() => download()}>
                                        <ListItemIcon sx={{
                                            color: grey[500],
                                            boxSizing: "content-box",
                                            padding: "5px"
                                        }}>
                                            <DownloadIcon sx={{ fontSize: '20px' }}></DownloadIcon>
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body2" color="text.primary">
                                                Download
                                            </Typography>
                                        </ListItemText>
                                    </ListItemButton>
                                </Box>
                                {props.publicShareEnabled && 
                                  <Box my={6}>
                                      <ListItemButton
                                          sx={{
                                              paddingLeft: "0px",
                                              paddingTop: "0px",
                                              paddingBottom: "0px"
                                          }}
                                          onClick={() => handleModalOpen()}>
                                          <ListItemIcon sx={{
                                              color: grey[500],
                                              boxSizing: "content-box",
                                              padding: "5px"
                                          }}>
                                              <LinkIcon sx={{ fontSize: '20px' }}/>
                                          </ListItemIcon>
                                          <ListItemText>
                                              <Typography variant="body2" color="text.primary">
                                                  Share file
                                              </Typography>
                                          </ListItemText>
                                      </ListItemButton>
                                  </Box>
                                }
                                <Box my={6}>
                                    <ListItemButton
                                        sx={{
                                            paddingLeft: "0px",
                                            paddingTop: "0px",
                                            paddingBottom: "0px"
                                        }}
                                        onClick={()=> window.open(`${props.transactionBaseUrl}/${activeFileGroup[0].txId}`, "_blank")}>
                                        <ListItemIcon sx={{
                                            color: grey[500],
                                            boxSizing: "content-box",
                                            padding: "5px"
                                        }}>
                                            <ViewTransactionIcon sx={{ fontSize: '20px' }}/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body2" color="text.primary">
                                                View transaction
                                            </Typography>
                                        </ListItemText>
                                    </ListItemButton>
                                </Box>
                                <Box my={6}>
                                    <ListItemButton
                                        sx={{
                                            paddingLeft: "0px",
                                            paddingTop: "0px",
                                            paddingBottom: "0px"
                                        }}
                                        onClick={() => print()}>
                                        <ListItemIcon sx={{
                                            color: grey[500],
                                            boxSizing: "content-box",
                                            padding: "5px"
                                        }}>
                                            <PrinterIcon sx={{ fontSize: '20px' }}></PrinterIcon>
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body2" color="text.primary">
                                                Print
                                            </Typography>
                                        </ListItemText>
                                    </ListItemButton>
                                </Box>
                                <Box my={6}>
                                    <ListItemButton sx={{
                                        paddingLeft: "0px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px"
                                    }}
                                        onClick={() => {
                                            setDarkMode(!darkMode)
                                            props.onThemeSwitch && props.onThemeSwitch()
                                        }}>
                                        <ListItemIcon sx={{
                                            color: grey[500],
                                            boxSizing: "content-box",
                                            padding: "5px"
                                        }}>
                                            {darkMode ? <LightModeGalleryIcon sx={{ fontSize: '20px' }} /> : <DarkModeGalleryIcon sx={{ fontSize: '20px' }} />}
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body2" color="text.primary">
                                                {darkMode ? "Light mode" : "Dark mode"}
                                            </Typography>
                                        </ListItemText>
                                    </ListItemButton>
                                </Box>
                            </List>
                        </Box>
                    </Drawer>
                </>
            )}
            {props.publicShareEnabled && 
              <DialogWithForm 
                dialogViewProps={shareDialoglViewProps}
                dialogViewOnSubmit={() => {
                  shareDialogViewOnSubmit(
                    `${props.publicShareBaseUrl}/${files[activeFileIndex]?.id}/${files[activeFileIndex]?.hash}`
                  );
                  handleModalOpen();
                  handleSnackbarOpen();
                }}
                dialogOpen={modalOpen} 
                onDialogClose={() => handleModalOpen()} 
                dialogData={{...files[activeFileIndex], value: `${props.publicShareBaseUrl}/${files[activeFileIndex]?.id}/${files[activeFileIndex]?.hash}`}}
              />
            }
            <SnackbarNotification 
              snackbarShow={snackbarOpen} 
              snackbarViewProps={shareSnackbarViewProps} 
              onSnackbarClose={handleSnackbarOpen}
            />
        </Box>
    )
}

export default Gallery
