import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useRef } from "react";
import muiTheme from "../../../theme/mui";
import FormSubmit from "./FormSubmit";

export interface DialogWithForm {
  dialogViewProps: DialogViewProps;
  dialogViewOnSubmit: () => void;
  dialogOpen: boolean;
  onDialogClose: () => void;
  dialogData?: DialogData;
}
export interface DialogViewProps {
  dialogName: string;
  Icon: (props: any) => React.ReactElement<any, any>;
  title: string;
  placeholder?: string;
  secondaryText?: string;
  nextButton: string;
}
export interface DialogData {
  id?: string;
  hash?: string;
  value?: string;
}

const DialogWithForm = (props: DialogWithForm) => {
  const {
    dialogOpen,
    onDialogClose,
    dialogData,
    dialogViewProps,
    dialogViewOnSubmit
  } = props;

  const theme = muiTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true
  });
  const modalInputRef = useRef(null);

  const MenuIcon = dialogViewProps.Icon;

  return (
    <Dialog
      open={dialogOpen}
      onClose={onDialogClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      sx={{
        width: "calc(100% - 32px)",
        margin: isDesktop ? "128px 16px 16px" : "64px 16px 16px"
      }}
      TransitionProps={{
        onEntered: () => modalInputRef.current?.select()
      }}
    >
      <FormSubmit onSubmit={dialogViewOnSubmit}>
        <DialogTitle id="form-dialog-title" sx={{ display: "flex" }}>
          <MenuIcon
            fontSize="medium"
            color="disabled"
            sx={{ marginRight: "8px" }}
          />
          <Typography className="strong" color="text.primary">
            {dialogViewProps.title}
          </Typography>
        </DialogTitle>

        {dialogViewProps.secondaryText && (
          <DialogContent>
            <Typography
              variant="body2"
              color="text.tertiary"
              style={{ whiteSpace: "pre-line" }}
              noWrap
            >
              {dialogViewProps.secondaryText}
            </Typography>
          </DialogContent>
        )}

        <DialogContent style={{ overflowY: "initial" }}>
          <TextField
            autoFocus
            fullWidth
            variant="outlined"
            inputRef={modalInputRef}
            id="modal-form-input"
            value={dialogData.value}
            placeholder={dialogViewProps.placeholder}
            type="text"
            sx={{ marginBottom: 0 }}
            InputProps={{
              sx: {
                height: "48px!important"
              }
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onDialogClose}
            color="primary"
            sx={{
              minWidth: "auto",
              marginLeft: isDesktop ? "24px!important" : "12px!important"
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth={false}
            type="submit"
            disableElevation
            sx={{
              minWidth: "auto",
              marginLeft: isDesktop ? "24px!important" : "12px!important"
            }}
          >
            {dialogViewProps.nextButton}
          </Button>
        </DialogActions>
      </FormSubmit>
    </Dialog>
  );
};

export default DialogWithForm;
